import { Link } from "gatsby";
import React from "react";

import { Button } from "../components/Button/Button";
import { Layout } from "../components/Layout/Layout";
import { Section, SectionWidth } from "../components/Section/Section";
import { SEO } from "../components/Seo/Seo";
import * as styles from "./404.module.scss";

const NotFoundPage = () => (
  <Layout>
    <SEO pageTitle="404" language="de" />
    <Section width={SectionWidth.NARROW} className={styles.notFoundSection}>
      <div>
        <h1 className={styles.notFound__headline}>404</h1>
        <div className={styles.notFound__content}>
          <h2>Die Seite konnte nicht gefunden werden.</h2>
          <Link to="/">
            <Button label="Zurück zur Startseite" />
          </Link>
        </div>
      </div>
    </Section>
  </Layout>
);

export default NotFoundPage;
